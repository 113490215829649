export default function Snap() {
  return (
    <svg
      height={125}
      width={125}
      className="mx-auto"
      id="Snap_Layer_1"
      data-name="Snap Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 144 144"
    >
      <defs>
        <clipPath id="snap-clip-path">
          <rect fill="none" x="32.31" y="19.89" width="79.38" height="104.21" />
        </clipPath>
      </defs>
      <g clipPath="url(#snap-clip-path)">
        <path
          fill="#dae8f2"
          d="M99.32,80.66c4.72,1.29,6.43,3.84,6.18,9.11.33.12.68.25,1,.39,4.92,2,6.86,8.11,3.47,12.14a124.12,124.12,0,0,1-12.85,13.76C86.52,125.35,69.05,127.39,56.8,118,50.38,113,47,106.34,45.68,98.47a29.17,29.17,0,0,1-.55-6.57c.43-6.11-1.79-11.24-5.14-16.11a13.21,13.21,0,0,1-1.94-4.27,7.35,7.35,0,0,1,3.52-7.88,7.17,7.17,0,0,1,8.24.34A23.89,23.89,0,0,1,56,71.09c.73,1.18,1.43,2.39,2.23,3.52,1.23,1.78,2.4,2.2,4.32,1.23a31.29,31.29,0,0,0,5.18-3.33C75.94,66,83.2,58.43,90.37,50.81c2.19-2.33,4.58-3.94,7.93-3.43a7.6,7.6,0,0,1,6.42,5.28,7.46,7.46,0,0,1-1.69,8c-2.87,3-5.88,5.92-8.83,8.88-.23.23-.45.48-.81.86a8.7,8.7,0,0,1,5.22,3.89,8.47,8.47,0,0,1,.71,6.35"
        />
        <path
          fill="#231f20"
          d="M92.46,96.77c.08,2.33.9,3.61,2.45,4.35a3.14,3.14,0,0,0,3.64-.21,22.91,22.91,0,0,0,3.15-3.52,4.35,4.35,0,0,0-2.43-6.76,2.64,2.64,0,0,0-2.06.38,26.31,26.31,0,0,0-3.55,3.53,10.25,10.25,0,0,0-1.2,2.23m-7.75-8.52c.18,2.33,1,3.66,2.63,4.4s2.64.49,4.09-.94,2.74-2.69,4-4.08a3.62,3.62,0,0,0-4.88-5.32,55.85,55.85,0,0,0-4.45,3.63,9.41,9.41,0,0,0-1.44,2.31m-6.64-9.71c.06,2.42,1,3.77,2.58,4.58a3.39,3.39,0,0,0,4.21-.7c.61-.61,1.38-1,2-1.65A20.71,20.71,0,0,0,90,77.46a4,4,0,0,0-1.1-5.39,4.25,4.25,0,0,0-5.65.2,51.14,51.14,0,0,0-3.94,4,9.88,9.88,0,0,0-1.21,2.28M81.3,87.1c-.28-.1-.5-.19-.73-.26-6.28-2-8-8.88-3.4-13.55,1.45-1.47,2.91-2.92,4.37-4.38,4.38-4.38,8.76-8.74,13.12-13.14a4.41,4.41,0,0,0,.18-6.32c-1.84-1.92-4.64-1.75-6.74.34C82,55.89,76,62.1,69.7,68c-4,3.75-8,7.6-13.6,9.14.07.49.12,1,.2,1.43a11.29,11.29,0,0,1-1.8,8,1.77,1.77,0,1,1-3.07-1.75,15.42,15.42,0,0,0,.85-1.67,7.18,7.18,0,0,0-1.15-7.68,34,34,0,0,1-2.65-3.57c-1.77-2.8-3.45-5.66-6.18-7.68a3.55,3.55,0,0,0-3.9-.53,3.73,3.73,0,0,0-2.46,3.58,7.27,7.27,0,0,0,1.25,3.57c4,5.61,6.22,11.62,5.93,18.66a28.93,28.93,0,0,0,3.3,14.59c3.26,6.3,10.4,7.66,15.67,2.93a46.86,46.86,0,0,0,3.26-3.63,1.67,1.67,0,0,1,2.42-.42,1.58,1.58,0,0,1,.48,2.38c-2.59,3.7-5.72,6.73-10.31,7.79-.86.2-1.76.26-2.75.4,10.67,7.5,29.54,5.27,39.53-8.52-4.45-2.27-5-3-6-8.29-5.15-.76-7.94-4.35-7.44-9.59m12.47-9.18c4.72,1.3,6.42,3.84,6.18,9.11l1,.39c4.92,2,6.86,8.11,3.46,12.15a124.82,124.82,0,0,1-12.84,13.75C81,122.61,63.5,124.65,51.25,115.23c-6.42-4.94-9.84-11.63-11.12-19.5a29.12,29.12,0,0,1-.55-6.57c.43-6.11-1.79-11.24-5.14-16.11a13,13,0,0,1-1.94-4.27A7.35,7.35,0,0,1,36,60.9a7.16,7.16,0,0,1,8.23.34,23.78,23.78,0,0,1,6.21,7.11c.73,1.18,1.43,2.39,2.22,3.53,1.24,1.77,2.4,2.19,4.32,1.22a31,31,0,0,0,5.19-3.33c8.2-6.54,15.46-14.08,22.63-21.7,2.19-2.33,4.58-3.93,7.92-3.42a7.57,7.57,0,0,1,6.43,5.27,7.5,7.5,0,0,1-1.69,8c-2.87,3-5.88,5.92-8.83,8.88-.23.23-.46.48-.82.86a8.62,8.62,0,0,1,5.22,3.9,8.44,8.44,0,0,1,.72,6.34"
        />
        <path
          fill="#231f20"
          d="M71.1,53.76a2,2,0,0,1-.75-.15,1.89,1.89,0,0,1-1-2.47L80.9,24.87a1.87,1.87,0,1,1,3.43,1.5L72.82,52.64a1.87,1.87,0,0,1-1.72,1.12"
        />
        <path
          fill="#231f20"
          d="M59.55,52.32a1.87,1.87,0,0,1-1.87-1.87V21.77a1.87,1.87,0,1,1,3.74,0V50.45a1.87,1.87,0,0,1-1.87,1.87"
        />
        <path
          fill="#231f20"
          d="M50.28,55.55a1.85,1.85,0,0,1-1.62-.93L34.32,29.77A1.85,1.85,0,0,1,35,27.22a1.88,1.88,0,0,1,2.56.68L51.9,52.74a1.87,1.87,0,0,1-.69,2.56,1.83,1.83,0,0,1-.93.25"
        />
      </g>
    </svg>
  );
}
