export default function LightningBolt() {
  return (
    <svg
      id="LB_Layer_1"
      data-name="LB Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 144 144"
      height={125}
      width={125}
      className="mx-auto"
    >
      <defs>
        <clipPath id="lb-clip-path">
          <rect fill="none" x="2.15" y="28.46" width="139.71" height="87.07" />
        </clipPath>
      </defs>
      <g clipPath="url(#lb-clip-path)">
        <path
          fill="#dae8f2"
          d="M57.66,113.4c-2.38-1.71-.4-6.94.71-10.22,2.69-7.93,5.52-15.81,8.3-23.72.23-.65.47-1.3.78-2.15-2.62-.25-5.09-.53-7.57-.71-3.16-.24-4.75-2.85-3.53-5.78q7-16.9,14.13-33.8a5.71,5.71,0,0,1,6-3.89c4.27.07,8.55.1,12.81.25,3.68.14,5,2.22,3.67,5.68q-3.5,9.21-7,18.43c-.17.45-.31.93-.52,1.57,2.57.25,5,.51,7.42.7,1.51.12,3,.37,3.67,1.9s.06,2.81-.85,4Q80.2,86.63,64.75,107.53c-1.85,2.51-5,7.52-7.09,5.87"
        />
        <path
          fill="#231f20"
          d="M66.83,73.29c-3.48,9.38-8.48,21.45-12.23,31.23a.52.52,0,0,0,.9.49c9.29-12.85,20.06-28.42,29.21-41l-12.66-1.8c3.36-8.73,6.58-17.11,9.92-25.77-4.12-.11-7.88-.13-11.64-.32a2.69,2.69,0,0,0-3,2C63.15,48.25,58.92,58.3,54.71,68.36c-.48,1.15-.94,2.3-1.48,3.62l13.6,1.31m-17,41.91c-2.49-1.79-.43-7.25.73-10.68,2.81-8.28,5.77-16.51,8.67-24.76.24-.68.49-1.36.82-2.25-2.74-.26-5.32-.55-7.91-.74-3.3-.25-5-3-3.69-6q7.35-17.65,14.75-35.3a6,6,0,0,1,6.25-4.06c4.46.07,8.92.1,13.38.27,3.84.14,5.2,2.31,3.83,5.92Q83,47.2,79.37,56.81c-.18.48-.32,1-.54,1.65,2.68.25,5.21.53,7.75.73,1.57.12,3.08.38,3.83,2s.06,2.94-.89,4.22Q73.4,87.23,57.27,109.07c-1.94,2.61-5.18,7.84-7.4,6.13"
        />
        <path
          fill="#231f20"
          d="M104,74a2.12,2.12,0,0,1,2.61-2.37l31.2,8.15a2.1,2.1,0,1,1-1.06,4.07l-31.2-8.15A2.11,2.11,0,0,1,104,74"
        />
        <path
          fill="#231f20"
          d="M105.82,60.92a2.12,2.12,0,0,1,1.75-2.41l31.85-5a2.11,2.11,0,1,1,.66,4.16l-31.85,5.05a2.11,2.11,0,0,1-2.41-1.75"
        />
        <path
          fill="#231f20"
          d="M100.6,51.2a2.09,2.09,0,0,1,.76-2l25-20.31a2.11,2.11,0,0,1,2.65,3.28l-25,20.3a2.11,2.11,0,0,1-3-.31,2.16,2.16,0,0,1-.45-1"
        />
        <path
          fill="#231f20"
          d="M38,60.63a2.31,2.31,0,0,1,0,.87,2.1,2.1,0,0,1-2.46,1.67l-31.69-6a2.1,2.1,0,1,1,.78-4.13l31.69,6A2.1,2.1,0,0,1,38,60.63"
        />
        <path
          fill="#231f20"
          d="M39.33,73.66a2.11,2.11,0,0,1-1.58,2.52L6.33,83.42a2.1,2.1,0,0,1-1-4.1l31.43-7.24a2.1,2.1,0,0,1,2.52,1.58"
        />
        <path
          fill="#231f20"
          d="M45.21,83a2.13,2.13,0,0,1-.62,2L21,107a2.1,2.1,0,1,1-2.86-3.08l23.58-22A2.1,2.1,0,0,1,45.21,83"
        />
      </g>
    </svg>
  );
}
