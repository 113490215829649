export default function CircleBottomRight() {
  return (
    <svg
      id="Circle_Bottom_Right_Layer_1"
      data-name="Cirlce Bottom Right Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 142.58 131.78"
      width="140px"
      height="130px"
    >
      <defs>
        <clipPath id="cbr-clip-path" transform="translate(31.32 -12.91)">
          <rect fill="none" x="-31.32" y="12.91" width="142.58" height="131.78" />
        </clipPath>
        <clipPath id="cbr-clip-path-3" transform="translate(31.32 -12.91)">
          <rect fill="none" x="-40.32" y="5.91" width="152.49" height="139.78" />
        </clipPath>
        <clipPath id="cbr-clip-path-4" transform="translate(31.32 -12.91)">
          <rect fill="none" x="49.53" y="77.37" width="70.73" height="60.73" />
        </clipPath>
      </defs>
      <g clipPath="url(#cbr-clip-path)">
        <g clipPath="url(#cbr-clip-path)">
          <g opacity="0.54">
            <g clipPath="url(#cbr-clip-path-3)">
              <path
                fill="#5b63ad"
                d="M-21.55,78.8h0c0-32.12,26-56.63,57.78-56.63a56.82,56.82,0,0,1,57,56.63,57.23,57.23,0,0,1-57,56.82c-31.79,0-57.78-25.07-57.78-56.82m-9.77,0h0c.2,36.39,29.76,65.89,67.55,65.89,37.38,0,66.75-29.5,66.94-65.89C103,42.41,73.61,12.91,36.23,12.91c-37.79,0-67.35,29.5-67.55,65.89"
                transform="translate(31.32 -12.91)"
              />
            </g>
          </g>
          <g opacity="0.54">
            <g clipPath="url(#cbr-clip-path-4)">
              <path
                fill="#b9d6e9"
                d="M84.9,137.09a26.36,26.36,0,1,0-26.37-26.36A26.36,26.36,0,0,0,84.9,137.09"
                transform="translate(31.32 -12.91)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
