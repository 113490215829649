export default function LightBlueCircle() {
  return (
    <svg
      id="LBC_Layer_1"
      data-name="LBC Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 160.48 157.51"
      width={160}
      height={158}
    >
      <defs>
        <clipPath id="lbc-clip-path" transform="translate(40.27 -0.05)">
          <rect fill="none" x="-40.27" y="0.04" width="160.48" height="157.51" />
        </clipPath>
        <clipPath id="lbc-clip-path-3" transform="translate(40.27 -0.05)">
          <rect fill="none" x="-49.27" y="-6.96" width="178.48" height="165.51" />
        </clipPath>
      </defs>
      <g clipPath="url(#lbc-clip-path)">
        <g clipPath="url(#lbc-clip-path)">
          <g opacity="0.27">
            <g clipPath="url(#lbc-clip-path-3)">
              <path
                fill="#8faddb"
                d="M-23,78.8h0c0-35.22,28.5-62.1,63.35-62.1a62.31,62.31,0,0,1,62.48,62.1A62.75,62.75,0,0,1,40.3,141.11C5.45,141.11-23,113.62-23,78.8m-10.72,0h0c.22,39.91,32.64,72.26,74.07,72.26,41,0,73.2-32.35,73.41-72.26C113.5,38.89,81.3,6.55,40.3,6.55c-41.43,0-73.85,32.34-74.07,72.25"
                transform="translate(40.27 -0.05)"
              />
              <path
                strokeWidth="13px"
                fill="none"
                stroke="#8faddb"
                d="M-23,78.8h0c0-35.22,28.5-62.1,63.35-62.1a62.31,62.31,0,0,1,62.48,62.1A62.75,62.75,0,0,1,40.3,141.11C5.45,141.11-23,113.62-23,78.8Zm-10.72,0h0c.22,39.91,32.64,72.26,74.07,72.26,41,0,73.2-32.35,73.41-72.26C113.5,38.89,81.3,6.55,40.3,6.55-1.13,6.55-33.55,38.89-33.77,78.8Z"
                transform="translate(40.27 -0.05)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
