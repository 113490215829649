export default function SearchSVG() {
  return (
    <svg
      id="Search_Layer_1"
      data-name="Search Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 26.47 26.76"
      width={20}
      height={20}
    >
      <defs>
        <clipPath id="search-clip-path" transform="translate(-58.76 -62.42)">
          <rect fill="none" x="58.76" y="62.42" width="26.47" height="26.76" />
        </clipPath>
      </defs>
      <g clipPath="url(#search-clip-path)">
        <circle fill="none" stroke="#fff" strokeWidth="2.4px" cx="11.33" cy="11.33" r="10.13" />
        <line
          fill="none"
          stroke="#fff"
          strokeWidth="2.4px"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="19.13"
          y1="19.58"
          x2="25.27"
          y2="25.56"
        />
      </g>
    </svg>
  );
}
