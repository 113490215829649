import baseUrl from "config";

export default async function BaseFetch(url, method, body = {}) {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Access-Control-Expose-Headers", true);
  headers.append("Access-Control-Allow-Origin", window.location.origin);

  const fullUrl = `${baseUrl}${url}`;

  const fetchObj = { method: method, headers: headers, credentials: "include" };

  if (Object.keys(body).length > 0) {
    fetchObj.body = JSON.stringify(body);
  }

  return await fetch(fullUrl, { ...fetchObj });
}
