import { useRouter } from "next/router";
import { getCookie, setCookies } from "cookies-next";
import { useEffect, useRef } from "react";
import BaseFetch from "util/baseFetch";

const texasBounds = {
  north: 36.52055616312709,
  south: 25.834127576702024,
  east: -93.51808428602985,
  west: -106.70325921117835,
};

const options = {
  types: ["geocode"],
  componentRestrictions: { country: "us" },
  fields: ["address_component"],
  strictBounds: true,
  bounds: texasBounds,
};

export default function Search() {
  const router = useRouter();

  const parsePlaceData = (place) => {
    let streetNumber, route, locality, postalCode, administrativeAreaLevel1;

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          streetNumber = component.long_name;
          break;
        }
        case "route": {
          route = component.long_name;
          break;
        }
        case "postal_code": {
          postalCode = component.long_name;
          break;
        }
        case "locality":
          locality = component.long_name;
          break;
        case "administrative_area_level_1": {
          administrativeAreaLevel1 = component.short_name;
          break;
        }
      }
    }

    return {
      streetNumber,
      route,
      locality,
      postalCode,
      administrativeAreaLevel1,
    };
  };

  const getPropertyByGeocode = async (place) => {
    if (place.address_components === undefined) {
      return;
    }
    const placeDate = parsePlaceData(place);

    const bodyRequest = {
      street_number: placeDate.streetNumber,
      route: placeDate.route,
      locality: placeDate.locality,
      postal_code: placeDate.postalCode,
      administrative_area_level_1: placeDate.administrativeAreaLevel1,
    };

    const response = await BaseFetch("/properties", "post", bodyRequest);
    const data = await response.json();
    console.log("DATA")
    console.log(data)
    const newPidCookie = JSON.parse(getCookie("pid") || "[]");
    if (!newPidCookie.includes(data.id)) {
      newPidCookie.push(data.id);
    }

    setCookies("pid", JSON.stringify(newPidCookie));

    router.push({
      pathname: "/loan_applications/new",
      query: { pid: data.id, step: 1 },
    });
  };

  const inputRef = useRef();

  useEffect(() => {
    if (window.google) {
      setupGoogleApi();
    } else {
      document.getElementById("google-api").addEventListener("load", setupGoogleApi);
    }
  }, []);

  function setupGoogleApi() {
    const google = window.google;

    try {
      const autocomplete = new google.maps.places.Autocomplete(inputRef.current, options);
      google.maps.event.addListener(autocomplete, "place_changed", function () {
        const place = autocomplete.getPlace();
        getPropertyByGeocode(place);
      });
    } catch {}
  }

  return (
    <input
      type="search"
      className="placeholder:text-gray-100 w-full h-10 bg-inherit pl-12 border-0 text-white rounded-3xl focus:shadow-outline focus:outline-0 outline-none transition-shadow duration-200"
      ref={inputRef}
      variant="landing"
      placeholder="Find Your Home"
    />
  );
}
