import Head from "next/head";
import { useTranslation } from "next-i18next";
import Script from "next/script";
import dynamic from "next/dynamic";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Navbar from "components/navbar";
import SnapIcon from "@/public/images/snap.js";
import LightningIcon from "@/public/images/lightningBolt";
import HandIcon from "@/public/images/hands.js";
import SearchIcon from "@/public/images/search";
import styles from "styles/landing.module.css";
import CircleBottomRight from "@/public/images/circleBottomRight";
import LightBlueCircle from "@/public/images/lightBlueCircle";
import Search from "components/search";
const HowItWorks = dynamic(() => import("features/landing/howItWorks"));
const Footer = dynamic(() => import("features/landing/footer"));

export default function Home() {
  const { t } = useTranslation("common");

  return (
    <>
      <Head>
        <title>{t("title")}</title>
        <meta name="description" content="Rooph Fast Loan" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar />

      <Script
        id="google-api"
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDAcUJ34Y6ddHnd110qdzqfxy0aLak0rvc&libraries=places"
      />

      <div className="pt-32 relative">
        <div className="absolute top-5 right-5">
          <CircleBottomRight />
        </div>

        <div className="pb-32 relative">
          <div className="absolute -bottom-16 -left-20">
            <LightBlueCircle />
          </div>

          <div className="max-w-screen-md text-center mx-auto px-1">
            <h2 style={{fontSize: '48px', fontWeight: '100'}} className={`${styles.bolderHeader} p-4 text-6xl md:text-7xl`}>{t("h1")}</h2>

            <p className="mb-4 p-4">
              Texas property tax loans are for anyone who is facing potential property tax delinquency or who has already become delinquent. All debts to the local taxing authority will be paid in full so you can get back to life as it should be.
            </p>
            <div className="shadow-[4px_5px_4px_#dceaf4] ml-auto mr-auto bg-gradient-to-b from-primary to-secondary border-0 relative rounded-3xl max-w-lg">
              <Search />
              <div className="absolute left-[15px] top-[10px]">
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-screen-md text-center mx-auto px-4">
          <h4 className={`${styles.purpleBoldHeader} text-4xl text-primary mb-2`}>Our Approach</h4>
          <div className="mb-8">
            Our loans will pay your overdue taxes, penalties, interest and collection fees while providing you with affordable monthly payments.
          </div>
        </div>

        <div className="max-w-screen-xl px-4 text-center mb-32 ml-auto mr-auto">
          <div className="md:flex">
            <div className="w-full mb-8 md:mb-0">
              <SnapIcon />
              <h6 className="mb-2 text-xl">Easy to Apply</h6>
              <span>
                Our quick and painless application process will have you lien-free in no time.
              </span>
            </div>

            <div className="w-full mb-8 md:mb-0 sm:mx-12">
              <LightningIcon />

              <h6 className="mb-2 text-xl">Quick Decision</h6>
              <span>We process your application quickly and efficiently. Pay off your property taxes with just a few easy clicks.</span>
            </div>

            <div className="w-full mb-8 md:mb-0">
              <HandIcon />
              <h6 className="mb-2 text-xl">Full Transparency</h6>
              <span>
                You know exactly your loan details with our simple loan management platform. No upfront costs, and no hidden fees.
              </span>
            </div>
          </div>
        </div>

        <HowItWorks />

        <Footer />
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
